<template>
  <div>
    <trac-loading v-if="loading" />
    <div class="grid grid-cols-12 gap-12 mb-12">
      <div class="col-span-8">
        <button
          class="my-5 inline-flex cursor-pointer items-center text-xs"
          @click="goBack"
        >
          <svg
            class="mr-2 w-3 mb-px h-3"
            viewBox="0 0 8 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.00195 1.00147L1.00195 7.00147L7.00195 13.0015"
              stroke="#013E9E"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Back
        </button>
      </div>
      <!-- <div class="col-span-4">
        <POSActions
          :item="paymentInfo"
          @save-ticket="saveTicket"
          @charge-amount="addToQuickSale"
        />
      </div> -->
    </div>

    <div class="grid grid-cols-12 gap-12">
      <div class="col-span-7">
        <div
          class="col-span-8 rounded-xl overflow-hidden border border-lightGray"
        >
          <trac-dashboard-table
            v-if="paymentInfo.items"
            :tableHeaders="tableHeaders"
            :data="paymentInfo.items"
          >
            <template #default="{ row, index }">
              <trac-dashboard-table-data>{{
                index + 1
              }}</trac-dashboard-table-data>
              <trac-dashboard-table-data>
                <span class="font-bold text-sm">{{ row.title }}</span>
              </trac-dashboard-table-data>
              <trac-dashboard-table-data>
                {{ row.salePrice | formatPrice }}
              </trac-dashboard-table-data>
              <trac-dashboard-table-data>
                x {{ Number(row.quantity).toLocaleString() }}
              </trac-dashboard-table-data>
              <trac-dashboard-table-data>
                <span
                  v-if="['amount', 'percentage'].includes(row.discount_type)"
                >
                  {{ getDiscountVal(row) | formatPrice }}
                </span>
                <span v-else>N/A</span>
              </trac-dashboard-table-data>
              <trac-dashboard-table-data>
                {{ getDiscount(row) | formatPrice }}
              </trac-dashboard-table-data>
            </template>
          </trac-dashboard-table>
        </div>
        <div class="flex flex-col items-end pt-6 gap-2">
          <div class="flex flex-col gap-1">
            <div class="flex justify-between gap-3">
              <p>Sub Total:</p>
              &nbsp;
              <span class="font-bold">
                {{ subTotal | formatPrice }}
              </span>
            </div>
            <div class="flex justify-between gap-3">
              <p>Total Discount:</p>
              &nbsp;
              <span class="font-bold">
                {{ totalDiscount | formatPrice }}
              </span>
            </div>
            <div class="flex justify-between gap-3">
              <p>Total:</p>
              &nbsp;
              <span class="font-bold">
                {{ total | formatPrice }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-span-5 bg-white rounded-lg big-shadow py-8 px-6 self-start"
      >
        <template v-if="!paymentSuccess">
          <div class="mb-2">
            <p class="text-xs text-primaryGray">Collect</p>
            <p class="font-bold text-2xl">{{ total | formatPrice }}</p>
          </div>

          <div>
            <p class="text-xs text-primaryGray mb-2">Choose payment method</p>
            <div class="relative">
              <!-- <trac-dropdown-exteneded
                neededProperty="name"
                :options="paymentMethods"
                selector="Select"
                @optionSelected="selectPaymentMethod"
              /> -->
              <div class="flex flex-col gap-2">
                <div
                  v-for="method in paymentMethods"
                  :key="method._id"
                  class="border border-primaryBlue rounded-lg px-2 py-2"
                  :class="{
                    'cursor-pointer': method._id !== paymentMethod,
                    'bg-primaryBlue text-white': method._id === paymentMethod,
                  }"
                  @click="selectPaymentMethod(method)"
                >
                  <div class="flex flex-row items-center gap-5">
                    <img src="../../../assets/svg/pay_method.svg" />
                    <span
                      class="uppercase text-xs"
                      :class="[
                        method._id === paymentMethod
                          ? 'text-white'
                          : 'text-primaryGray',
                      ]"
                      >{{ method.name }}</span
                    >
                  </div>
                </div>
              </div>
              <!-- <div
                v-if="paymentMethod"
                class="z-10 text-white flex items-center gap-3 p-3 absolute top-0 left-0 w-full bg-bg-lightBlue rounded-md pointer-events-none bg-lightBlue h-full"
              >
                <div class="w-8 h-8">
                  <img
                    src="@/assets/svg/payment-icon.svg"
                    alt="payment-icon"
                    class="w-full h-full"
                  />
                </div>
                <span class="uppercase font-medium text-xs">
                  {{ paymentLabel }}
                </span>
              </div> -->
            </div>
            <span
              v-if="loadingBankDetails"
              class="text-primaryGray text-xs block my-2"
            >
              Loading...
            </span>
            <div
              v-else-if="
                bankInfo.bank_details.length &&
                ['fixed_account'].includes(paymentMethod)
              "
            >
              <div
                class="p-3 mb-4 rounded-md border border-lightGray mt-4 flex flex-col gap-4 overflow-scroll"
                style="max-height: 300px"
              >
                <div
                  v-for="(detail, index) in bankInfo.bank_details"
                  :key="index"
                >
                  <div class="w-8 h-8">
                    <img
                      class="w-full h-full"
                      :src="detail.bank_logo"
                      :alt="detail.custom_account_name"
                    />
                  </div>
                  <div>
                    <h6 class="font-bold text-sm">
                      {{ detail.custom_account_name }}
                    </h6>
                    <p class="text-primaryGray text-xs">
                      {{ detail.bank_name }} - {{ detail.account_number }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="bankInfo.payment_method !== 'fixed_account'"
              class="flex flex-col gap-4 mt-4"
            >
              <trac-input
                v-model="cashToCollect"
                type="number"
                placeholder="Collect cash"
              />
              <!-- <trac-input
              v-if="paymentMethod !== 'cash'"
              v-model="paymentReferenceDetails"
              placeholder="Payment reference"
            /> -->
            </div>
          </div>

          <div
            v-if="
              paymentReferenceDetails &&
              paymentReferenceDetails.payment_reference
            "
          >
            <p class="text-xs">
              <span class="text-primaryGray">Payment reference: </span>
              {{ paymentReferenceDetails.payment_reference }}
            </p>
            <button
              type="button"
              class="text-xs underline text-lightBlue"
              @click="paymentLog = true"
            >
              Update
            </button>
          </div>

          <div class="flex justify-center mt-10">
            <button
              class="bg-lightBlue w-2/4 justify-center py-3 font-medium flex items-center text-white rounded-md px-4 whitespace-no-wrap uppercase"
              :class="{
                'opacity-50': disableMakePaymentBtn,
              }"
              :disabled="disableMakePaymentBtn"
              @click="createSale"
            >
              <span style="font-size: 10px">
                {{ paymentNotLinked ? 'LINK' : 'MAKE' }} PAYMENT
              </span>
            </button>
          </div>
        </template>
        <PaymentSuccess
          v-else
          :sale-detail="{
            ...saleDetail,
          }"
          @back="goBack"
        />
      </div>
    </div>

    <trac-modal
      v-if="paymentLog"
      size="max-w-6xl z-5 ml-64"
      @close="paymentLog = false"
    >
      <PaymentLog
        linkable
        hide-status
        :payment-method-id="paymentMethod"
        :selected-store="store"
        :payment-reference="
          paymentReferenceDetails && paymentReferenceDetails.payment_reference
        "
        @select="
          (e) => {
            paymentReferenceDetails = e;
            paymentLog = false;
          }
        "
      />
    </trac-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_ID,
  GET_USER_DATA,
  SAVE_LOCAL_DB_DATA,
} from '../../../browser-db-config/localStorage';
import { getDiscountValue, paymentMethods } from '../../../utils';
// import POSActions from "../POSDashboard/POSActions.vue";
import { eventBus } from '../../../eventBus';
import PaymentLog from '../../pos/PaymentLog.vue';
import PaymentSuccess from './PaymentSuccess.vue';

export default {
  components: { PaymentLog, PaymentSuccess },
  name: 'POSPaymentV2',
  data() {
    return {
      paymentInfo: {},
      paymentMethod: '',
      paymentMethods,
      loadingBankDetails: false,
      store: {},
      bankInfo: {
        bank_details: [],
      },
      loading: false,
      cashToCollect: '',
      paymentReferenceDetails: null,
      paymentLog: false,
      paymentSuccess: false,
      tableHeaders: [
        {
          name: 'NAME',
        },
        {
          name: 'ITEM',
        },
        {
          name: 'UNIT PRICE',
        },
        {
          name: 'QUANTITY',
        },
        {
          name: 'DISCOUNT',
        },
        {
          name: 'TOTAL',
          classValue: 'w-1/6',
        },
      ],
      saleDetail: {},
    };
  },
  created() {
    this.paymentInfo = GET_LOCAL_DB_DATA('pos-sales-data');
    this.store = GET_LOCAL_DB_DATA('pos-sales-store');
  },
  computed: {
    totalDiscount() {
      return this.paymentInfo.items.reduce(
        (acc, item) => acc + getDiscountValue(item),
        0
      );
    },
    paymentNotLinked() {
      return (
        !this.paymentReferenceDetails &&
        ['fixed_account', 'pos'].includes(this.paymentMethod)
      );
    },
    paymentLabel() {
      return this.paymentMethods.find(
        (method) => method._id === this.paymentMethod
      )?.name;
    },
    subTotal() {
      return this.paymentInfo.items.reduce((acc, item) => {
        const totalPrice = item.salePrice * (item.quantity || 1);
        return acc + totalPrice;
      }, 0);
    },
    total() {
      return this.paymentInfo.items.reduce((acc, item) => {
        const totalPrice = this.getDiscount(item);
        return acc + totalPrice;
      }, 0);
    },
    disableMakePaymentBtn() {
      return this.loadingBankDetails || !this.paymentMethod;
    },
    setupPayload() {
      return {
        ...this.paymentInfo,
        items: this.paymentInfo.items?.map((item) => ({
          id: item.id,
          name: item.title,
          item_note: '',
          quantity: item.quantity,
          price: item.salePrice * item.quantity - getDiscountValue(item),
          unit_price: item.salePrice,
          discount: item.discount,
          discount_type: item.discount_type || 'none',
          is_quick_sale: !!item.is_quick_sale,
          is_variant: item.is_variant,
          is_stock_managed: item.is_stock_managed,
        })),
        total_price: this.total,
        sub_total: this.subTotal,
        amount_tendered:
          this.bankInfo.payment_method !== 'fixed_account'
            ? Number(this.cashToCollect)
            : this.total,
        store_id: this.store.id,
        store_name: this.store.name,
        business_id: GET_USER_BUSINESS_ID(),
        payments: [
          {
            amount_paid:
              this.bankInfo.payment_method !== 'fixed_account'
                ? this.cashToCollect
                  ? Number(this.cashToCollect)
                  : this.total
                : this.total,
            payment_method: this.paymentMethod,
            payment_reference: this.paymentReferenceDetails?.payment_reference,
            payment_channel: this.paymentReferenceDetails?.payment_channel,
          },
        ],
        sale_mode: 'online',
      };
    },
  },
  methods: {
    ...mapActions(['FETCH_PAYMENTS_V2', 'MAKE_SALE_V2']),
    getDiscountVal(data) {
      return getDiscountValue(data);
    },
    getDiscount(item) {
      if (item.discount_type === 'amount') {
        return item.salePrice * item.quantity - item.discount;
      }

      if (item.discount_type === 'percentage') {
        return (
          item.salePrice * item.quantity -
          (item.discount / 100) * (item.salePrice * item.quantity)
        );
      }

      return item.salePrice * item.quantity;
    },
    // addToQuickSale(item) {
    //   this.paymentInfo.items.push({
    //     id: item._id,
    //     title: item.title,
    //     item_note: "",
    //     quantity: item.count,
    //     salePrice: item.quickSaleTotal,
    //     costPrice: item.quickSaleTotal,
    //     discount: 0,
    //     discount_type: "none",
    //     is_quick_sale: true,
    //     is_variant: false,
    //   });
    // },
    async selectPaymentMethod(payment) {
      this.paymentMethod = payment._id;
      if (payment._id !== 'fixed_account') {
        this.paymentReferenceDetails = null;
        this.bankInfo = {
          bank_details: [],
        };
      }

      if (['fixed_account'].includes(payment._id)) {
        this.loadingBankDetails = true;
        try {
          const res = await this.FETCH_PAYMENTS_V2({
            paymentID: payment._id,
            storeID: this.store._id,
          });
          if (res.bank_details) {
            this.bankInfo = res;
          }
        } catch (error) {
          return eventBus.$emit('trac-alert', {
            message: error?.response?.data?.message,
          });
        } finally {
          this.loadingBankDetails = false;
        }
      }
    },
    async createSale(data) {
      const isOnline = await this.NetworkCheck();

      if (isOnline) {
        this.makePayment(data);
      } else {
        this.createOfflineSale();
      }
    },
    async createOfflineSale() {
      const payload = { ...this.setupPayload };
      payload.offline_sale_id = this.$GlobalOfflineManager.generateUniqueID(26);
      payload.sale_mode = 'offline';
      payload.offline_date = new Date().toISOString().replace('Z', '');
      const unsyncedSales = await this.$GlobalOfflineManager.getAll(
        'pos_unsynced'
      );
      await this.$GlobalOfflineManager.insert(
        'pos_unsynced',
        unsyncedSales?.length ? [...unsyncedSales, payload] : [payload]
      );
      this.requestSync('pos');
      this.paymentSuccess = true;
    },
    async makePayment({ ticketName, onSuccess, onError } = {}) {
      if (this.paymentNotLinked) {
        this.paymentLog = true;
        return;
      }

      const payload = { ...this.setupPayload };
      payload.ticket_status = ticketName ? 0 : 1;
      payload.ticket_name = ticketName;

      if (this.paymentMethod === 'pos') {
        const { name, email, _id: id } = GET_USER_DATA();
        payload.sale_agent = {
          name,
          email,
          id,
        };
      }

      this.loading = true;
      try {
        const res = await this.MAKE_SALE_V2(payload);
        if (res.status) {
          this.saleDetail = res.data ?? {};
          SAVE_LOCAL_DB_DATA('pos-sales-data', '');
          eventBus.$emit('trac-alert', {
            message: 'Sale created successfully',
          });
          onSuccess?.();
          this.paymentSuccess = true;
          // this.$router.push({ name: "Pos" });
        } else {
          onError?.();
        }
      } catch (error) {
        onError?.(error);
        return eventBus.$emit('trac-alert', {
          message: error?.response?.data?.error,
        });
      } finally {
        this.loading = false;
      }
    },
    // async saveTicket(data) {
    //   this.createSale(data);
    // },
    goBack() {
      this.$router.push({
        name: 'PosDashboard',
        state: this.paymentInfo,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
