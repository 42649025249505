<template>
  <div class="px-8 py-8 h-full">
    <trac-loading v-if="isLoading" />
    <div>
      <img class="m-auto my-4" src="../../../assets/svg/tick.svg" />
      <div class="my-6">
        <p class="text-xs my-2 text-gray-600">
          Amount Paid:
          <span class="text-black">{{
            saleDetail.amount_tendered | formatPrice
          }}</span>
        </p>
        <!-- <p
          class="text-xs my-2 text-gray-600"
          v-if="
            !sale.loyaltyData.active &&
            sale.customer._id &&
            businesCustomerLoaylty
          "
        >
          Accumulated Loyalty Points:
          <span class="text-black">{{
            accumulatedLoyaltyPoints | formatPrice
          }}</span>
        </p> -->
        <!-- <p class="text-xs my-2 text-gray-600">
          Balance:
          <span class="text-black">{{ calcBalance | formatPrice }}</span>
        </p> -->
      </div>
      <trac-input
        :disabled="!saleDetail._id"
        v-model="reciepientEmail"
        class="mt-4"
        placeholder="Email to..."
      ></trac-input>
      <div class="flex justify-end">
        <button
          :disabled="reciepientEmail.length <= 6 || !saleDetail._id"
          class="w-1/4 mt-5 justify-center py-3 font-medium flex items-center text-xs text-white rounded-md px-6 whitespace-no-wrap uppercase"
          :class="[
            reciepientEmail.length <= 6 || !saleDetail._id
              ? 'bg-primaryGray'
              : 'bg-lightBlue',
          ]"
          @click="sendReceipt"
        >
          Send
        </button>
      </div>
    </div>
    <div class="flex flex-row justify-between gap-5 mt-24">
      <button
        class="bg-lightBlue w-2/4 justify-center py-2 font-medium flex items-center text-xs text-white rounded-md px-4 whitespace-no-wrap uppercase"
        @click="printReceipt"
      >
        Print
      </button>
      <button
        class="bg-lightBlue w-2/4 justify-center py-2 font-medium flex items-center text-xs text-white rounded-md px-4 whitespace-no-wrap uppercase"
        @click="$emit('back')"
      >
        Make New Sale
      </button>
    </div>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";

export default {
  name: "PaymentSuccess",
  props: {
    saleDetail: {
      type: Object,
      default: () => {},
    },
  },
  emits: ["back"],
  data() {
    return {
      reciepientEmail: "",
      isLoading: false,
    };
  },
  methods: {
    async printReceipt() {
      setTimeout(() => {
        print();
      }, 200);
    },
    async sendReceipt() {
      this.isLoading = true;
      // Setup emails
      let emails = this.reciepientEmail.split(",");

      emails = emails.map((email) => email.trim());

      const res = await Promise.all(
        emails.map(async (email) => {
          try {
            await this.$store.dispatch("SEND_RECEIPT", {
              sale: this.saleDetail,
              email: email,
            });
            return {
              status: true,
              message: "Email sent successfully",
            };
          } catch (error) {
            return {
              status: false,
              message: "Something went wrong. Try again",
            };
          }
        })
      );

      // Send Email

      this.isLoading = false;

      if (res?.every((r) => r.status)) {
        eventBus.$emit("trac-alert", {
          message: res?.[0].message,
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
